import { ChainId } from 'random-dex-sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.SEPOLIA]: '0xCcA665012f37CA32F912E98409432B3fD4f23D08',
  [ChainId.HOLESKY]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.BNB]: '0x07e576A55b80c5D12F4dA946238Fa945f8c42f2E',
  [ChainId.AVAX]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.BASE]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.OPTIMISM]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.BLAST]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.AMOY]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.ARBITRUM]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54',
  [ChainId.LINEA]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
