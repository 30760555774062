import { useWeb3React } from '@web3-react/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import usePrevious from '../../hooks/usePrevious'

import Modal from '../Modal'
import { ButtonGray } from '../Button'

const StyledCloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  padding: 2rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 1rem`};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const NetworkButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
  `};
`

const NetworkButton = styled(ButtonGray)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const supportedNetworks = [
  {
    name: 'ETH Sepolia',
    id: 'sepolia',
    chainId: '0xaa36a7',
    chainName: 'Sepolia Testnet',
    rpcUrl: 'https://ethereum-sepolia-rpc.publicnode.com',
    explorerUrl: 'https://sepolia.etherscan.io/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png'
  },
  {
    name: 'ETH Holesky',
    id: 'holesky',
    chainId: '0x4268',
    chainName: 'Holesky Testnet',
    rpcUrl: 'https://ethereum-holesky-rpc.publicnode.com',
    explorerUrl: 'https://holesky.etherscan.io/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png'
  },
  {
    name: 'BSC Testnet',
    id: 'bsc',
    chainId: '0x61',
    chainName: 'BNB Testnet',
    rpcUrl: 'https://bsc-testnet-rpc.publicnode.com',
    explorerUrl: 'https://testnet.bscscan.com/',
    currency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png'
  },
  {
    name: 'Fuji Testnet',
    id: 'avax',
    chainId: '0xa869',
    chainName: 'Avax Fuji Testnet',
    rpcUrl: 'https://avalanche-fuji-c-chain-rpc.publicnode.com',
    explorerUrl: 'https://testnet.snowtrace.io/',
    currency: { name: 'AVAX', symbol: 'AVAX', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/avalanchec/info/logo.png'
  },
  {
    name: 'Base Testnet',
    id: 'base',
    chainId: '0x14a34',
    chainName: 'Base Testnet',
    rpcUrl: 'https://base-sepolia-rpc.publicnode.com',
    explorerUrl: 'https://sepolia.basescan.org/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/base/info/logo.png'
  },
  {
    name: 'OP Testnet',
    id: 'optimism',
    chainId: '0xaa37dc',
    chainName: 'Optimism Testnet',
    rpcUrl: 'https://optimism-sepolia-rpc.publicnode.com',
    explorerUrl: 'https://sepolia-optimism.etherscan.io/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/optimism/info/logo.png'
  },
  {
    name: 'Blast Testnet',
    id: 'blast',
    chainId: '0xa0c71fd',
    chainName: 'Blast Testnet',
    rpcUrl: 'https://sepolia.blast.io',
    explorerUrl: 'https://sepolia.blastscan.io/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/blast/info/logo.png'
  },
  {
    name: 'Amoy Testnet',
    id: 'amoy',
    chainId: '0x13882',
    chainName: 'Amoy Testnet',
    rpcUrl: 'https://polygon-amoy-bor-rpc.publicnode.com',
    explorerUrl: 'https://amoy.polygonscan.com/',
    currency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png'
  },
  {
    name: 'Arbitrum',
    id: 'arbitrum',
    chainId: '0x66eee',
    chainName: 'Arbitrum Testnet',
    rpcUrl: 'https://arbitrum-sepolia-rpc.publicnode.com',
    explorerUrl: 'https://sepolia.arbitrum.io/',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/arbitrum/info/logo.png'
  },
  {
    name: 'Linea Testnet',
    id: 'linea',
    chainId: '0xe705',
    chainName: 'Linea Testnet',
    rpcUrl: 'https://rpc.sepolia.linea.build',
    explorerUrl: 'https://sepolia.lineascan.build',
    currency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/linea/info/logo.png'
  }
]

export default function NetworkModal({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
  const { account } = useWeb3React()
  const previousAccount = usePrevious(account)

  useEffect(() => {
    if (account && !previousAccount && isOpen) {
      onDismiss()
    }
  }, [account, previousAccount, onDismiss, isOpen])

  async function switchNetwork(networkId: string) {
    const network = supportedNetworks.find(net => net.id === networkId)
    if (!network) return
    const isMetamask = window.ethereum && window.ethereum.isMetaMask

    try {
      if (window.ethereum && isMetamask) {
        await (window.ethereum as any)?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: network.chainId }]
        })
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error) {
      if ((error as any).code === 4902) {
        try {
          await (window.ethereum as any)?.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: network.chainId,
                chainName: network.chainName,
                nativeCurrency: network.currency,
                rpcUrls: [network.rpcUrl],
                blockExplorerUrls: [network.explorerUrl]
              }
            ]
          })
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } catch (addError) {
          console.error(`Failed to add the ${network.chainName}`, addError)
        }
      } else {
        console.error(`Failed to switch to the ${network.chainName}`, error)
      }
    }
  }

  function chooseNetworkContent() {
    return (
      <UpperSection>
        <StyledCloseIcon onClick={onDismiss}>
          <CloseColor />
        </StyledCloseIcon>
        <HeaderRow>Choose Network</HeaderRow>
        <ContentWrapper>
          <h5>
            Please connect to the appropriate Ethereum Testnet
            <NetworkButtons>
              {supportedNetworks.map(network => (
                <NetworkButton key={network.id} onClick={() => switchNetwork(network.id)}>
                  <img src={network.icon} alt={`${network.name} icon`} width={20} height={20} />
                  {network.name}
                </NetworkButton>
              ))}
            </NetworkButtons>
          </h5>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <Wrapper>{chooseNetworkContent()}</Wrapper>
    </Modal>
  )
}
