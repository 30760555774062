import { ChainId, JSBI, Percent, Token, WETH } from 'random-dex-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected } from '../connectors'
// import { fortmatic, portis, walletconnect, walletlink } from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export { PRELOADED_PROPOSALS } from './proposals'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.SEPOLIA]: new Token(ChainId.SEPOLIA, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.HOLESKY]: new Token(ChainId.HOLESKY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.BNB]: new Token(ChainId.BNB, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.AVAX]: new Token(ChainId.AVAX, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.BASE]: new Token(ChainId.BASE, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.OPTIMISM]: new Token(ChainId.OPTIMISM, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.BLAST]: new Token(ChainId.BLAST, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.AMOY]: new Token(ChainId.AMOY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.ARBITRUM]: new Token(ChainId.ARBITRUM, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, UNI_ADDRESS, 18, 'UNI', 'Uniswap')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {}

const WETH_ONLY: ChainTokenList = {
  [ChainId.SEPOLIA]: [WETH[ChainId.SEPOLIA]],
  [ChainId.HOLESKY]: [WETH[ChainId.HOLESKY]],
  [ChainId.BNB]: [WETH[ChainId.BNB]],
  [ChainId.AVAX]: [WETH[ChainId.AVAX]],
  [ChainId.BASE]: [WETH[ChainId.BASE]],
  [ChainId.OPTIMISM]: [WETH[ChainId.OPTIMISM]],
  [ChainId.BLAST]: [WETH[ChainId.BLAST]],
  [ChainId.AMOY]: [WETH[ChainId.AMOY]],
  [ChainId.ARBITRUM]: [WETH[ChainId.ARBITRUM]],
  [ChainId.LINEA]: [WETH[ChainId.LINEA]]
}

export const V2_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.SEPOLIA]: '0x1cA91381B6A6FFA0436bE1C829BfAa25F7D3191c', // by Web3Swag
  [ChainId.HOLESKY]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.BNB]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.AVAX]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.BASE]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.OPTIMISM]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.BLAST]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.AMOY]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.ARBITRUM]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88', // by Web3Swag
  [ChainId.LINEA]: '0x15165a529F1310F1a361cbDbcf1f4b222212bd88' // by Web3Swag
}

export const ROUTER_ADDRESSES: { [chainId: number]: string } = {
  [ChainId.SEPOLIA]: '0xDC97706e9fa6538dCd19F814069460787eE5f053', // by Web3Swag
  [ChainId.HOLESKY]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.BNB]: '0x24E7f67b7789555EaE221317Be9BB4B6c67a0a54', // by Web3Swag
  [ChainId.AVAX]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.BASE]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.OPTIMISM]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.BLAST]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.AMOY]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.ARBITRUM]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029', // by Web3Swag
  [ChainId.LINEA]: '0x548625e4184F59d1AFCe7cbe67A67d5D9eF39029' // by Web3Swag
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY
}

export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.SEPOLIA]: {},
  [ChainId.HOLESKY]: {}
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.SEPOLIA]: {},
  [ChainId.HOLESKY]: {},
  [ChainId.BNB]: {}
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.SEPOLIA]: [],
  [ChainId.HOLESKY]: [],
  [ChainId.BNB]: []
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.SEPOLIA]: [],
  [ChainId.HOLESKY]: [],
  [ChainId.BNB]: []
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  }
  /*,
    WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  } */
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]
