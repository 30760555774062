import { UNSUPPORTED_LIST_URLS } from './../../constants/lists'
import { ChainId, Token } from 'random-dex-sdk'
import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../index'
// import sortByListPriority from 'utils/listSort'
import UNSUPPORTED_TOKEN_LIST from '../../constants/tokenLists/uniswap-v2-unsupported.tokenlist.json'

type TagDetails = Tags[keyof Tags]
export interface TagInfo extends TagDetails {
  id: string
}

export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo
  public readonly tags: TagInfo[]
  constructor(tokenInfo: TokenInfo, tags: TagInfo[]) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.tokenInfo = tokenInfo
    this.tags = tags
  }
  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI
  }
}

export type TokenAddressMap = Readonly<
  { [chainId in ChainId]: Readonly<{ [tokenAddress: string]: { token: WrappedTokenInfo; list: TokenList } }> }
>

const EMPTY_LIST: TokenAddressMap = {
  [ChainId.SEPOLIA]: {},
  [ChainId.HOLESKY]: {},
  [ChainId.BNB]: {},
  [ChainId.AVAX]: {},
  [ChainId.BASE]: {},
  [ChainId.OPTIMISM]: {},
  [ChainId.BLAST]: {},
  [ChainId.AMOY]: {},
  [ChainId.ARBITRUM]: {},
  [ChainId.LINEA]: {}
}

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

export function listToTokenMap(list: TokenList): TokenAddressMap {
  const result = listCache?.get(list)
  if (result) {
    return result
  } else {
    return EMPTY_LIST
  }
}

export function useAllLists(): {
  readonly [url: string]: {
    readonly current: TokenList | null
    readonly pendingUpdate: TokenList | null
    readonly loadingRequestId: string | null
    readonly error: string | null
  }
} {
  return useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)
}

function combineMaps(map1: TokenAddressMap, map2: TokenAddressMap): TokenAddressMap {
  return {
    11155111: { ...map1[11155111], ...map2[11155111] },
    17000: { ...map1[17000], ...map2[17000] },
    97: { ...map1[97], ...map2[97] },
    43113: { ...map1[43113], ...map2[43113] },
    84532: { ...map1[84532], ...map2[84532] },
    11155420: { ...map1[11155420], ...map2[11155420] },
    168587773: { ...map1[168587773], ...map2[168587773] },
    80002: { ...map1[80002], ...map2[80002] },
    421614: { ...map1[421614], ...map2[421614] },
    59141: { ...map1[59141], ...map2[59141] }
  }
}

function useCombinedTokenMapFromUrls(urls: string[] | undefined): TokenAddressMap {
  return useMemo(() => {
    return EMPTY_LIST
  }, [])
}

export function useActiveListUrls(): string[] | undefined {
  return useSelector<AppState, AppState['lists']['activeListUrls']>(state => state.lists.activeListUrls)?.filter(
    url => !UNSUPPORTED_LIST_URLS.includes(url)
  )
}

export function useInactiveListUrls(): string[] {
  const lists = useAllLists()
  const allActiveListUrls = useActiveListUrls()
  return Object.keys(lists).filter(url => !allActiveListUrls?.includes(url) && !UNSUPPORTED_LIST_URLS.includes(url))
}

export function useCombinedActiveList(): TokenAddressMap {
  const activeListUrls = useActiveListUrls()
  return useCombinedTokenMapFromUrls(activeListUrls)
}

export function useCombinedInactiveList(): TokenAddressMap {
  const allInactiveListUrls: string[] = useInactiveListUrls()
  return useCombinedTokenMapFromUrls(allInactiveListUrls)
}

export function useDefaultTokenList(): TokenAddressMap {
  // Since DEFAULT_TOKEN_LIST is removed, return an empty map or null
  return EMPTY_LIST
}

export function useUnsupportedTokenList(): TokenAddressMap {
  const localUnsupportedListMap = listToTokenMap(UNSUPPORTED_TOKEN_LIST)
  const loadedUnsupportedListMap = useCombinedTokenMapFromUrls(UNSUPPORTED_LIST_URLS)
  return combineMaps(localUnsupportedListMap, loadedUnsupportedListMap)
}

export function useIsListActive(url: string): boolean {
  const activeListUrls = useActiveListUrls()
  return Boolean(activeListUrls?.includes(url))
}
