import { Currency, Price } from 'random-dex-sdk'
import { useMemo } from 'react'

/**
 * Returns the price in USDC of the input currency
 * @param currency currency to compute the USDC price of
 */
export default function useUSDCPrice(currency?: Currency): Price | undefined {
  return useMemo(() => {
    return undefined
  }, [])
}
